import React, { Component } from 'react'
import { Button, Typography } from '@material-ui/core'
import useLinkStyles from './Link'

class ExternalLink extends Component {
  render() {
    const { className, children, ...other } = this.props
    return (
      <Button {...other} className={className}>
        <Typography align="left">{children}</Typography>
      </Button>
    )
  }
}

export default useLinkStyles(ExternalLink)
