import React, { Component } from 'react'
import { Link } from 'gatsby'

import Icon from './Icon'
import { withStyles, createStyles } from '@material-ui/core'

const styles = createStyles({
  icon: {
    maxWidth: '100%',
    width: '4.9375rem',
    height: 'auto',
  },
})

export class Logo extends Component {
  render() {
    const { classes } = this.props
    return (
      <Link to="/">
        <Icon className={classes.icon} />
      </Link>
    )
  }
}

export default withStyles(styles)(Logo)
