import { createStyles, withStyles, Typography } from '@material-ui/core'
import React, { Component } from 'react'

import Logo from './LogoFinal'
import Container from '../../Container'

const styles = (theme) =>
  createStyles({
    footer: {
      padding: '3.75rem 0',
      color: '#333',
      fontSize: '0.9375rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      backgroundColor: '#fffdfc',
    },
    image: {
      width: '13.25rem',
      height: 'auto',
    },
    credits: {
      borderTop: '1px solid rgba(126, 130, 143, 0.09)',
      marginTop: '3.125rem',
      width: '100%',
    },
    legal: {
      padding: '1.25rem 0',
    },
  })

class Footer extends Component {
  render() {
    const { classes } = this.props
    return (
      <footer className={classes.footer}>
        <Container>
          <Logo className={classes.image} />
          <Typography>
            All in one solution for your contactless dine-in, delivery and
            takeaways.
          </Typography>
          <div className={classes.credits}>
            <Typography className={classes.legal}>
              © 2020 Air Dining Inc | All rights reserved
            </Typography>
            <Typography>
              Made with{' '}
              <span role="image" aria-label="Heart">
                ❤️
              </span>{' '}
              by <a href="https://siddhantgoswami.com/">Siddhant Goswami</a>
            </Typography>
          </div>
        </Container>
      </footer>
    )
  }
}

export default withStyles(styles)(Footer)
