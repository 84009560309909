import React, { Component } from 'react'
import { createStyles, IconButton, withStyles, Hidden } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

const styles = (theme) =>
  createStyles({
    toggle: {
      color: 'black',
      borderRadius: '0.25rem',
      marginLeft: 'auto',
    },
    inactive: {
      backgroundColor: 'rgba(126, 130, 143, 0.1)',
    },
    active: {
      backgroundColor: theme.palette.primary.main,
    },
  })

class Toggle extends Component {
  handleClick = () => {
    const { setIsMenuOpen, isMenuOpen } = this.props
    setIsMenuOpen(!isMenuOpen)
  }

  getClasses = () => {
    const { isMenuOpen, classes } = this.props
    if (isMenuOpen) {
      return `${classes.toggle} ${classes.active}`
    }
    return `${classes.toggle} ${classes.inactive}`
  }

  render() {
    return (
      <Hidden smUp implementation="js">
        <IconButton
          edge="start"
          className={this.getClasses()}
          color="inherit"
          aria-label="menu"
          onClick={this.handleClick}
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    )
  }
}

export default withStyles(styles)(Toggle)
