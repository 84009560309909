import React, { Component } from 'react'
import {
  createStyles,
  Drawer as BaseDrawer,
  Hidden,
  withStyles,
} from '@material-ui/core'

import Links from './Links'

const styles = createStyles({
  drawer: {
    top: '110px',
  },
})

class MobileMenu extends Component {
  handleBackdropClick = () => {
    const { setIsMenuOpen } = this.props
    setIsMenuOpen(false)
  }

  render() {
    const { isMenuOpen, classes } = this.props
    return (
      <>
        <Hidden smUp implementation="js">
          <BaseDrawer
            open={isMenuOpen}
            variant="temporary"
            anchor="top"
            className={classes.drawer}
            PaperProps={{
              className: classes.drawer,
            }}
            ModalProps={{
              onBackdropClick: this.handleBackdropClick,
              BackdropProps: {
                invisible: true,
              },
            }}
          >
            <Links />
          </BaseDrawer>
        </Hidden>
        <Hidden xsDown implementation="js">
          <Links />
        </Hidden>
      </>
    )
  }
}

export default withStyles(styles)(MobileMenu)
