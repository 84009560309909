import React from 'react'
const { createStyles, withStyles } = require('@material-ui/core')

const styles = (theme) =>
  createStyles({
    link: {
      padding: '1.5625rem 1rem',
      textDecoration: 'none',
      color: '#28224b',
      opacity: 0.8,
      justifyContent: 'flex-start',
      [theme.breakpoints.up('sm')]: {
        padding: '1.25rem 1rem',
      },
    },
  })

const useLinkStyles = (Link) => {
  const StyledLink = ({ classes, ...other }) => (
    <Link className={classes.link} {...other} />
  )
  return withStyles(styles)(StyledLink)
}

export default useLinkStyles
