import React, { Component } from 'react'
import {
  withStyles,
  createStyles,
  AppBar,
  Toolbar,
  Typography,
  Hidden,
} from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    appbar: {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      justifyContent: 'center',
      backgroundPosition: '0% 50%',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url(/images/Wave2.svg)',
      minHeight: '3rem',
      padding: '0.3125rem 0',
      boxSizing: 'border-box',
    },
    text: {
      color: 'hsla(0, 0%, 100%, 0.8)',
      fontSize: '0.9375rem',
    },
  })

class Jobs extends Component {
  render() {
    const { classes } = this.props
    return (
      <Hidden xsDown implementation="js">
        <AppBar className={classes.appbar} position="static">
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.text} align="center">
              We're hiring now!
            </Typography>
          </Toolbar>
        </AppBar>
      </Hidden>
    )
  }
}

export default withStyles(styles)(Jobs)
