import React, { Component } from 'react'
import { AppBar, Toolbar, createStyles, withStyles } from '@material-ui/core'

import Logo from './Logo'
import Toggle from './Toggle'
import MobileMenu from './MobileMenu'
import Container from '../../Container'

const styles = () => {
  const backgroundColor = 'white'
  return createStyles({
    appbar: {
      backgroundColor,
      boxShadow: 'none',
    },
    toolbar: {
      backgroundColor,
      color: 'black',
      position: 'relative',
      justifyContent: 'space-between',
    },
  })
}

class Nav extends Component {
  state = {
    isMenuOpen: false,
  }

  setIsMenuOpen = (isMenuOpen) => this.setState({ isMenuOpen })

  render() {
    const { classes } = this.props
    const { isMenuOpen } = this.state
    return (
      <AppBar className={classes.appbar} position="static">
        <Container>
          <Toolbar className={classes.toolbar}>
            <Logo />
            <Toggle
              setIsMenuOpen={this.setIsMenuOpen}
              isMenuOpen={isMenuOpen}
            />
            <MobileMenu
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={this.setIsMenuOpen}
            />
          </Toolbar>
        </Container>
      </AppBar>
    )
  }
}

export default withStyles(styles)(Nav)
