import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import Nav from './Nav'
import Footer from './Footer'
import Jobs from './Jobs'

const TITLE =
  'Air Dining | Contactless Dining, TakeAway and Delivery for Your Restaurants'
const DESCRIPTION =
  'Post pandemic, customers will expect absolute safety in terms of hygiene & distancing. Air Dining will enable your restaurant to offer a contactless menu, orders & payment to your customers.'

export class Layout extends Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <Helmet>
          <title>{TITLE}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={TITLE} />
          <meta name="description" content={DESCRIPTION} />
          <link rel="canonical" href="https://airdining.in" />
        </Helmet>
        <Jobs />
        <Nav />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
