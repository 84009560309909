import React, { Component } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { createStyles, withStyles, Typography } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    link: {
      padding: '1.5625rem 1rem',
      textDecoration: 'none',
      color: '#28224b',
      opacity: 0.8,
      [theme.breakpoints.up('sm')]: {
        padding: '1.25rem 1rem',
      },
    },
    active: {
      color: theme.palette.primary.main,
    },
  })

class Link extends Component {
  render() {
    const { children, classes, ...other } = this.props
    return (
      <GatsbyLink
        {...other}
        className={classes.link}
        activeClassName={classes.active}
      >
        <Typography>{children}</Typography>
      </GatsbyLink>
    )
  }
}

export default withStyles(styles)(Link)
