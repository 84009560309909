import {
  createStyles,
  withStyles,
  Container as BaseContainer,
} from '@material-ui/core'
import React, { Component } from 'react'

const styles = () => {
  const paddingX = '9px'
  return createStyles({
    container: {
      paddingLeft: paddingX,
      paddingRight: paddingX,
      maxWidth: '75rem',
    },
  })
}

class Container extends Component {
  render() {
    const { classes, ...other } = this.props
    return (
      <BaseContainer className={classes.container} {...other}></BaseContainer>
    )
  }
}

export default withStyles(styles)(Container)
