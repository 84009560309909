import React, { Component } from 'react'

import Link from './PageLink'
import ExternalLink from './ExternalLink'
import { createStyles, withStyles } from '@material-ui/core'

const styles = (theme) =>
  createStyles({
    links: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  })

class Links extends Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.links}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <ExternalLink href="https://goswamisiddh.typeform.com/to/WOgUiiTa">
          Careers
        </ExternalLink>
        <Link to="/contact">Contact</Link>
      </div>
    )
  }
}

export default withStyles(styles)(Links)
